import React from 'react';
import DesignThinking from 'views/ExhibitionScience';

const DesktopAppPage = () => {
  return <DesignThinking />;
};

export default DesktopAppPage;





