import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/ScienceClub/ScienceExhibition/1.jpg';
// import p2 from 'assests/Photos/Clubs/ScienceClub/ScienceExhibition/2.jpg';
// import p3 from 'assests/Photos/Clubs/ScienceClub/ScienceExhibition/3.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import SideBarSci from 'views/ScienceClub/SideBarSci';
const ExhibitionScience = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/ScienceExhibition/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/ScienceExhibition/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/ScienceClub/ScienceExhibition/3.webp`;
  
  const photos = [
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
      tittle: "Sound Visualizer",
    
    },
    
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
      tittle: "Analogy of Space Curve",
     
    },
  
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
      tittle: "Faraday’s Pen",
      
    },
 
  



    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Exhibition of working models in Science
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9 18 - 20 November 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Jawaharlal Nehru Planetarium organised a three day exhibition ‘Science in Action’ from 18 - 20

<br></br>
November 2022. Three teams of Class 9 represented the school in the event. It was a real learning
experience for the students. Many schools, subject experts and eminent personnel visited the
exhibition, thus enabling the students to share and enrich their conceptual knowledge on the models
presented.The exhibition enabled them to get an insight on the practical aspects of scientific
concepts.
        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
<br/>
        </Typography>

      <Box>
        <ImageList
          // variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
               
             
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                tittle={item.tittle}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
              <Typography variant='h6' align='center' border={1}>
          {item.tittle}
        </Typography>
                
            </ImageListItem>
          ))}
        </ImageList>
      
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBarSci />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ExhibitionScience;